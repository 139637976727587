import axios from 'axios';
import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store';
import { APP_URL } from '../utils';
import { routes } from './routes'
import notie from 'notie'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: "history",
	// base:  'http://localhost:8080',
	routes,
});

const getWalletBalance = async (to, next, token, getUser) => {
	try {
		const res = await axios.get(APP_URL + `/lenders/${getUser.lenders[0].id}/wallet-balance`, {
			headers: {
				Authorization: `Bearer ${token}`,
				"Access-Control-Allow-Origin": "*"
			}
		})
		if (res.status === 200) {
			Store.commit('SET_WALLET_BALANCE', res.data.data)
		}
	} catch (e) {
		console.log(e)
		if (e.response) {
			if (e.response.status === 401) {
				localStorage.clear()
				return next({
					path: "/login",
					query: { redirect: to.fullPath },
				})
			}
		}
	}
}

const getProfileStatus = async (to, next, token, getUser) => {
	try {
		const res = await axios.get(`${APP_URL}/lenders/${getUser.lenders[0].id}/profile/status`, {
			headers: {
				Authorization: `Bearer ${token}`,
				"Access-Control-Allow-Origin": "*"
			}
		})
		if (res.status === 200) {
			if (res.data.data.documentationStatus === 'approved' && res.data.data.profileStatus === 'approved') {
				Store.commit('SET_ACCOUNT_STATUS', 'Active')
				return next()
			} else {
				Store.commit('SET_ACCOUNT_STATUS', 'Inactive')
				if (['Compliance', 'Get Started'].includes(to.name)) {
					return next()
				} else {
					notie.alert({
						type: 'info',
						text: 'Your account has not been fully verified and you have been redirected to the current page',
						time: 7
					})
					return next('/compliance/review')
				}
			}
		}
	} catch (e) {
		console.log(e)
		if (e.response) {
			if (e.response.status === 401) {
				localStorage.clear()
				return next({
					path: "/login",
					query: { redirect: to.fullPath },
				})
			}
		}
	}
}

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
	const token = localStorage.getItem('token')
	const getUser = JSON.parse(localStorage.getItem('userDetails'))

	if (requiresAuth) {
		if (to.name === 'Borrower Details') {
			if (to.name === 'Borrower Details') {
				getWalletBalance(to, next, token, getUser)
				getProfileStatus(to, next, token, getUser)
				return next()
			}
			if (from.name !== 'Origination') {
				return next('/origination/pending')
			}
		}

		if (!token) {
			localStorage.clear()
			return next({
				path: "/login",
				query: { redirect: to.fullPath },
			})
		}

		getWalletBalance(to, next, token, getUser,)
		getProfileStatus(to, next, token, getUser)
	} else {
		return next()
	}
})

export default router
