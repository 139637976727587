export const BASE_API = process.env.VUE_APP_BASE_API;
export const APP_URL = process.env.VUE_APP_APP_URL;

export const AUTH_ROUTES = {
  SIGNUP: '/signup',
  LOGIN: '/login',
  JOIN_TEAM: '/team/join/:lenderId/:userId/:token',
  FORGOT_PASSWORD: '/password/forgot',
  RESET_PASSWORD: '/password/reset/:id/:token'
}

export const DASHBOARD_ROUTES = {
  GET_STARTED: '/get-started',
  COMPLIANCE: '/compliance/:step',
  HOME: '/',
  ORIGINATION: {
    BASE: '/origination/:tab',
    BORROWER: '/origination/borrower/:select'
  },
  REPORTS: '/reports',
  BILLINGS: {
    BASE: '/billings',
    PRICING: '/billings/pricing'
  },
  ACTIVITY: '/activity',
  SETTINGS: '/settings/:type',
  MANAGE_ROLES: '/settings/team/roles',
  SUPPORT: '/support',
  VERIFICATION: {
    BASE: '/verification/:mode',
    BORROWER: '/verification/borrower/:id'
  }
}