import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import { applicationData } from '../constants/mockData'
Vue.use(Vuex)

const getUser = JSON.parse(localStorage.getItem('userDetails'))

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token'),
    APP_URL: process.env.VUE_APP_BASE_API,
    user: {},
    tour: {
      stage: 1,
      title: 'Home',
      description: 'View metrics and charts to know how your loan products are performing.',
      start: false,
      done: false
    },
    applicationData: [],
    borrowerData: {},
    walletBalance: {},
    complianceStatus: {},
    accountStatus: null,
    salaryVerification: {},
    allSubscription: null
  },
  mutations: {
    SET_TOUR(state, data) {
      state.tour = data
    },
    SET_APPLICATION_DATA(state, data) {
      state.applicationData = data
    },
    SET_BORROWER_DATA(state, data) {
      state.borrowerData = data
    },
    USER_TOKEN(state, data) {
      state.token = data
    },
    SET_USER(state, data) {
      state.user = data
    },
    SET_WALLET_BALANCE(state, data) {
      state.walletBalance = data
    },
    SET_COMPLIANCE_STATUS(state, data) {
      state.complianceStatus = data
    },
    SET_ACCOUNT_STATUS(state, data) {
      state.accountStatus = data
    },
    SET_SALARY_VERIFICATION(state, data) {
      state.salaryVerification = data
    },
    SET_ALL_SUBSCRIPTION(state, data) {
      state.allSubscription = data
    }
  },
  actions: {
    setData(ctx) {
      ctx.commit('SET_APPLICATION_DATA', applicationData)
    },
    getAllSubscription({commit, state}) {
      axios.get(state.APP_URL + '/subscription', {
        headers: {
          Authorization: `Bearer ${state.token}`
        }
      })
      .then(res => {
        if(res.status === 200) {
          return commit('SET_ALL_SUBSCRIPTION', res.data.data)
        }
      })
      .catch(e => {
        return e
      })
    },
    async getBorrower({ commit, state }, id) {
      try {
        const res = await axios.get(`${state.APP_URL}/lenders/${getUser.lenders[0].id}/loan-application/${id}`, {
          headers: {
            Authorization: `Bearer ${state.token}`
          }
        })
        if(res.status === 200) {
          commit('SET_BORROWER_DATA', res.data.data)
        }
        return res;
      } catch (e) {
        return e
      }
    },
  },
  modules: {
  }
})
