import { AUTH_ROUTES, DASHBOARD_ROUTES } from "../constants/routes";
import { redirectIfAuthenticated } from "../middlewares";


function loadView(view) {
	return () => import(/* webpackChunkName: "about" */ `../views/${view}`);
}

export const routes = [
	{ path: '/compliance', redirect: '/compliance/company' },
	{ path: '/origination', redirect: '/origination/pending' },
	{ path: '/settings', redirect: '/settings/profile' },
  {
		path: '*',
		name: '404',
		component: loadView('NotFound.vue')
	},
  {
		path: AUTH_ROUTES.SIGNUP,
		name: "Signup",
		component: loadView("auth/Signup.vue"),
		beforeEnter: redirectIfAuthenticated,
	},
  {
		path: AUTH_ROUTES.LOGIN,
		name: "Login",
		component: loadView("auth/Login.vue"),
		beforeEnter: redirectIfAuthenticated,
	},
  {
		path: AUTH_ROUTES.JOIN_TEAM,
		name: "Join Team",
		component: loadView("auth/JoinTeam.vue"),
		// beforeEnter: redirectIfAuthenticated,
	},
  {
		path: AUTH_ROUTES.FORGOT_PASSWORD,
		name: "Forgot Password",
		component: loadView("auth/ForgotPassword.vue"),
		beforeEnter: redirectIfAuthenticated,
	},
  {
		path: AUTH_ROUTES.RESET_PASSWORD,
		name: "Reset Password",
		component: loadView("auth/ResetPassword.vue"),
		beforeEnter: redirectIfAuthenticated,
	},
	{
		path: DASHBOARD_ROUTES.GET_STARTED,
		name: 'Get Started',
		component: loadView('dashboard/GetStarted.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.COMPLIANCE,
		name: 'Compliance',
		component: loadView('dashboard/Compliance.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.HOME,
		name: 'Home',
		component: loadView('dashboard/Home.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.ORIGINATION.BASE,
		name: 'Origination',
		component: loadView('dashboard/origination/Base.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.ORIGINATION.BORROWER,
		name: 'Borrower Details',
		component: loadView('dashboard/origination/Borrower.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.REPORTS,
		name: 'Reports',
		component: loadView('dashboard/Home.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.BILLINGS.BASE,
		name: 'Billings',
		component: loadView('dashboard/billings/Base.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.BILLINGS.PRICING,
		name: 'Pricing',
		component: loadView('dashboard/billings/Pricing.vue'),
		beforeEnter: (to, from, next) => {
			if(from.name !== 'Billings') return next('/billings')
			else return next()
		},
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.ACTIVITY,
		name: 'Activity',
		component: loadView('dashboard/Home.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.SETTINGS,
		name: 'Settings',
		component: loadView('dashboard/Settings.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.MANAGE_ROLES,
		name: 'Manage Roles',
		component: loadView('dashboard/Settings.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.SUPPORT,
		name: 'Support',
		component: loadView('dashboard/Support.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.VERIFICATION.BASE,
		name: 'Verification',
		component: loadView('dashboard/verification/Base.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.VERIFICATION.BORROWER,
		name: 'Verification Details',
		component: loadView('dashboard/verification/Borrower.vue'),
		meta: {
			requiresAuth: true,
		}
	},
]