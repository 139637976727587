<template>
  <div class="bg-drawer pt-4 pb-12 w-fit-content fixed left-0 top-0 bottom-0 flex flex-col overflow-y-scroll drawer-scroll">
    <div class="p-2 rounded-lg flex items-center justify-between mx-3 xl:mx-6">
      <div class="flex items-center">
        <img v-if="getUser.lenders[0].logo" :src="getUser.lenders[0].logo" alt="" class="w-10 h-10 rounded-lg object-cover border border.gray.500 border-drawer-img">
        <div v-else class="w-10 h-10 rounded-lg bg-success-bg text-success text-4 flex items-center justify-center font-family-semibold border border-gray-500">{{ getUser.lenders[0].name.charAt(0).toUpperCase() }}</div>
        <div v-if="getUser" :class="showFullLink ? '' : 'hidden'" class="xl:block ml-2">
          <p class="text-white font-family-semibold text-xs">{{ getUser.lenders[0].name.slice(0, 20) }}</p>
          <span class="text-drawer-text text-xs">{{ `${getUser.user.firstName} ${getUser.user.lastName}`.slice(0, 20) }}</span>
        </div>
      </div>
      <svg class="ml-2" width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.00025 6.50025C4.74425 6.50025 4.48825 6.40225 4.29325 6.20725L0.29325 2.20725C-0.09775 1.81625 -0.09775 1.18425 0.29325 0.79325C0.68425 0.40225 1.31625 0.40225 1.70725 0.79325L5.01225 4.09825L8.30525 0.91825C8.70425 0.53525 9.33525 0.54625 9.71925 0.94325C10.1032 1.34025 10.0922 1.97425 9.69525 2.35725L5.69525 6.21925C5.50025 6.40725 5.25025 6.50025 5.00025 6.50025Z" fill="#A4A6B3"/>
      </svg>
    </div>

    <div @click="showFullDrawer" :class="showFullLink ? 'mr-auto ml-4 transform rotate-180' : 'mx-auto'" class="xl:hidden mt-10 bg-gray-300 p-4 min-w-10 min-h-10 flex items-center justify-center rounded-full">
      <svg width="9" height="10" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.40012 7.00082C8.40012 7.35921 8.26293 7.7176 7.98993 7.99059L2.39009 13.5904C1.8427 14.1378 0.957924 14.1378 0.410539 13.5904C-0.136846 13.0431 -0.136846 12.1583 0.410539 11.6109L5.03741 6.98402L0.585534 2.37394C0.0493481 1.81536 0.0647476 0.931982 0.620533 0.394397C1.17632 -0.143189 2.06389 -0.127789 2.60008 0.427996L8.00673 6.02784C8.26993 6.30084 8.40012 6.65083 8.40012 7.00082" fill="#A4A6B3"/>
      </svg>
    </div>
    
    <div :class="showFullLink ? 'mx-3' : 'mx-auto'" class="mt-10 pb-4 xl:mx-6 w-fit-content">
      <router-link to="/get-started" :class="path === '/get-started' ? 'bg-drawer-active' : ''" class="flex items-center p-4.5 rounded-lg xl:w-52 hover:bg-drawer-active my-1">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.39258 10.6069L6.63544 6.63544L10.6069 5.39258L9.36401 9.36401L5.39258 10.6069Z" :stroke="path === '/get-started' ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
          <circle cx="8" cy="8" r="7.5" :stroke="path === '/get-started' ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [path === '/get-started' ? 'text-white' : 'text-drawer-text']]">Get Started</p>
      </router-link>

      <router-link to="/compliance/company" :class="compliancePaths.includes(path) ? 'bg-drawer-active' : ''" class="flex items-center p-4.5 rounded-lg xl:w-52 hover:bg-drawer-active my-1">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7483 1H5.25081C2.63805 1 1 2.84995 1 5.46789V12.5321C1 15.1501 2.63027 17 5.25081 17H12.7475C15.3689 17 17 15.1501 17 12.5321V5.46789C17 2.84995 15.3689 1 12.7483 1Z" :stroke="compliancePaths.includes(path) ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6 9L8.00056 12L12 6" :stroke="compliancePaths.includes(path) ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [compliancePaths.includes(path) ? 'text-white' : 'text-drawer-text']]">Compliance</p>
      </router-link>

      <router-link to="/" :class="[path === '/' ? 'bg-drawer-active' : '', tour.start && tour.stage === 1 ? 'bg-tour-active shadow-tour' : '']" class="flex items-center p-4.5 rounded-lg xl:w-52 hover:bg-drawer-active my-1">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.90532 14.4634V12.2037C5.90531 11.629 6.37388 11.1619 6.95444 11.1581H9.08102C9.66435 11.1581 10.1372 11.6262 10.1372 12.2037V14.4704C10.1371 14.9584 10.5305 15.3573 11.0233 15.3687H12.441C13.8543 15.3687 15 15.3687 15 13.9695V6.40709C14.9925 5.85666 14.7314 5.33978 14.2912 5.00355L9.44254 1.1368C8.59312 0.463516 7.38563 0.463516 6.53621 1.1368L1.70886 5.01057C1.26693 5.34544 1.00544 5.86318 1 6.41411V13.9695C1 15.3687 2.1457 15.3687 3.55899 15.3687H4.97671C5.48174 15.3687 5.89114 14.9634 5.89114 14.4634" :stroke="path === '/' || tour.start && tour.stage === 1 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [path === '/' || tour.start && tour.stage === 1 ? 'text-white' : 'text-drawer-text']]">Home</p>
      </router-link>
    </div>

    <div class="w-full h-full border-t flex flex-col border-drawer-border min-h-50 overflow-y-scroll drawer-scroll">
      <div :class="showFullLink ? 'mx-3' : 'mx-auto'" class="pt-4 pb-4 xl:mx-6 w-fit-content">
        <router-link to="/origination/pending?page=1&limit=10" :class="[originationPaths.includes(path) || originationDetailsPaths.includes(path) ? 'bg-drawer-active' : '', tour.start && tour.stage === 2 ? 'bg-tour-active shadow-tour' : '']" class="flex items-center p-4.5 rounded-lg xl:w-52 hover:bg-drawer-active my-1">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9366 7.12727C16.404 3.84944 13.6199 1.4109 10.3005 1.31491C10.1426 1.30872 9.98883 1.36555 9.87294 1.47286C9.75704 1.58017 9.68857 1.72916 9.68262 1.88699V1.88699V1.94039L10.0564 7.53154C10.0809 7.90792 10.4045 8.19402 10.781 8.17227L16.3874 7.79851C16.5454 7.78683 16.6922 7.71263 16.7953 7.59236C16.8984 7.47209 16.9492 7.31568 16.9366 7.15778V7.12727Z" :stroke="originationPaths.includes(path) || originationDetailsPaths.includes(path) || tour.start && tour.stage === 2 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.41784 4.6408C6.77447 4.55831 7.13942 4.74079 7.28741 5.07559C7.32617 5.15433 7.34948 5.23978 7.35606 5.3273C7.43234 6.41044 7.59252 8.78268 7.68405 10.0641C7.69968 10.295 7.80666 10.5101 7.98131 10.6618C8.15596 10.8136 8.38388 10.8895 8.61464 10.8727V10.8727L13.321 10.5828C13.5311 10.5702 13.7371 10.645 13.8902 10.7894C14.0433 10.9338 14.1299 11.1351 14.1295 11.3456V11.3456C13.9388 14.1876 11.8969 16.5634 9.11593 17.1793C6.33493 17.7951 3.4809 16.5035 2.10816 14.0077C1.69836 13.2894 1.43885 12.4953 1.34538 11.6736C1.30542 11.4214 1.29009 11.166 1.29961 10.9108C1.30771 7.87583 3.4392 5.26077 6.41022 4.6408" :stroke="originationPaths.includes(path) || originationDetailsPaths.includes(path) || tour.start && tour.stage === 2 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [originationPaths.includes(path) || originationDetailsPaths.includes(path) || tour.start && tour.stage === 2 ? 'text-white' : 'text-drawer-text']]">Origination</p>
        </router-link>

        <!-- <router-link to="/reports" :class="[path === '/reports' ? 'bg-drawer-active' : '', tour.start && tour.stage === 3 ? 'bg-tour-active shadow-tour' : '']" class="flex items-center p-4.5 rounded-lg xl:w-52 hover:bg-drawer-active my-1">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.14243 7.50195V13.2187" :stroke="path === '/reports' || tour.start && tour.stage === 3 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.03159 4.7666V13.2189" :stroke="path === '/reports' || tour.start && tour.stage === 3 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.8568 10.5225V13.2183" :stroke="path === '/reports' || tour.start && tour.stage === 3 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9046 0.666992H5.09508C2.37285 0.666992 0.666504 2.59373 0.666504 5.32129V12.6794C0.666504 15.4069 2.36492 17.3337 5.09508 17.3337H12.9046C15.6348 17.3337 17.3332 15.4069 17.3332 12.6794V5.32129C17.3332 2.59373 15.6348 0.666992 12.9046 0.666992Z" :stroke="path === '/reports' || tour.start && tour.stage === 3 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [path === '/reports' || tour.start && tour.stage === 3 ? 'text-white' : 'text-drawer-text']]">Reports</p>
        </router-link> -->

        <router-link to="/billings" :class="[['/billings', '/billings/pricing'].includes(path) ? 'bg-drawer-active' : '', tour.start && tour.stage === 4 ? 'bg-tour-active shadow-tour' : '']" class="flex items-center p-4.5 rounded-lg xl:w-52 hover:bg-drawer-active my-1">
          <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.0321 9.9962H13.6585C12.4198 9.99544 11.4158 8.99217 11.415 7.75347C11.415 6.51477 12.4198 5.5115 13.6585 5.51074H17.0321" :stroke="['/billings', '/billings/pricing'].includes(path) || tour.start && tour.stage === 4 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.0404 7.702H13.7806" :stroke="['/billings', '/billings/pricing'].includes(path) || tour.start && tour.stage === 4 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.45606 0.5H12.6589C15.074 0.5 17.032 2.45792 17.032 4.87305V10.8539C17.032 13.269 15.074 15.227 12.6589 15.227H5.45606C3.04093 15.227 1.08301 13.269 1.08301 10.8539V4.87305C1.08301 2.45792 3.04093 0.5 5.45606 0.5Z" :stroke="['/billings', '/billings/pricing'].includes(path) || tour.start && tour.stage === 4 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.86279 4.28208H9.36192" :stroke="['/billings', '/billings/pricing'].includes(path) || tour.start && tour.stage === 4 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [['/billings', '/billings/pricing'].includes(path) || tour.start && tour.stage === 4 ? 'text-white' : 'text-drawer-text']]">Billings</p>
        </router-link>

        <router-link to="/verification/request" :class="verificationPaths.includes(path) || $route.name === 'Verification Details' ? 'bg-drawer-active' : ''" class="flex items-center p-4.5 rounded-lg xl:w-52 hover:bg-drawer-active my-1">
          <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.07192 17.3327C7.96872 17.3327 7.86553 17.309 7.77241 17.2608L4.7715 15.7074C3.91997 15.2661 3.25384 14.7709 2.7337 14.194C1.59524 12.9323 0.961839 11.3127 0.951771 9.63261L0.916536 4.1029C0.912341 3.4647 1.32426 2.89187 1.94005 2.67614L7.45026 0.755006C7.77745 0.638152 8.14239 0.636518 8.47545 0.749286L14.0066 2.60505C14.6258 2.81179 15.0444 3.38053 15.0478 4.01792L15.083 9.55171C15.0939 11.2293 14.4823 12.8555 13.3615 14.1311C12.8472 14.7161 12.187 15.2187 11.3438 15.6681L8.36974 17.2567C8.27746 17.3065 8.17511 17.3319 8.07192 17.3327" :stroke="verificationPaths.includes(path) || $route.name === 'Verification Details' ? 'white' : '#A4A6B3'"/>
            <path d="M5.5 9L7.16713 11.5L10.5 6.5" :stroke="verificationPaths.includes(path) || $route.name === 'Verification Details' ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [verificationPaths.includes(path) || $route.name === 'Verification Details' ? 'text-white' : 'text-drawer-text']]">Verification</p>
        </router-link>
      </div>

      <div :class="[showFullLink ? 'mx-3' : 'mx-auto', tour.start && tour.stage === 3 ? 'bg-tour-active shadow-tour rounded-lg' : '']" class="mt-auto w-fit-content xl:mx-6 mb-16">
        <!-- <router-link to="/activity" :class="path === '/activity' ? 'bg-drawer-active' : ''" class="flex items-center p-4.5 rounded-lg xl:w-52 hover:bg-drawer-active my-1">
          <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6344 7.04418C11.6344 8.49918 10.4544 9.67835 8.9994 9.67835C7.5444 9.67835 6.36523 8.49918 6.36523 7.04418C6.36523 5.58835 7.5444 4.40918 8.9994 4.40918C10.4544 4.40918 11.6344 5.58835 11.6344 7.04418Z" :stroke="path === '/activity' || tour.start && tour.stage === 5 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99817 13.129C12.1715 13.129 15.074 10.8473 16.7082 7.04398C15.074 3.24065 12.1715 0.958984 8.99817 0.958984H9.0015C5.82817 0.958984 2.92567 3.24065 1.2915 7.04398C2.92567 10.8473 5.82817 13.129 9.0015 13.129H8.99817Z" :stroke="path === '/activity' || tour.start && tour.stage === 5 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [path === '/activity' || tour.start && tour.stage === 5 ? 'text-white' : 'text-drawer-text']]">Activity log</p>
        </router-link> -->

        <router-link to="/settings/profile?tab=personal" :class="settingsPath.includes(path) ? 'bg-drawer-active' : ''" class="flex items-center p-4.5 rounded-lg xl:w-52 hover:bg-drawer-active my-1">
          <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3392 5.35305L14.8205 4.45298C14.3817 3.69138 13.4092 3.42864 12.6466 3.86564V3.86564C12.2835 4.07949 11.8504 4.14017 11.4425 4.03428C11.0347 3.92839 10.6858 3.66464 10.4727 3.30118C10.3355 3.07016 10.2619 2.80704 10.2591 2.53841V2.53841C10.2714 2.10773 10.109 1.69038 9.80864 1.38143C9.50832 1.07249 9.09573 0.89826 8.66487 0.898438H7.61987C7.19776 0.898433 6.79305 1.06664 6.49528 1.36583C6.19752 1.66502 6.03126 2.07053 6.03329 2.49264V2.49264C6.02078 3.36415 5.31068 4.06405 4.43909 4.06396C4.17046 4.06117 3.90733 3.98749 3.67631 3.85038V3.85038C2.91367 3.41339 1.94121 3.67612 1.5024 4.43772L0.945574 5.35305C0.507293 6.1137 0.766452 7.08555 1.52528 7.52697V7.52697C2.01854 7.81174 2.32239 8.33803 2.32239 8.90759C2.32239 9.47715 2.01854 10.0034 1.52528 10.2882V10.2882C0.767415 10.7267 0.507974 11.6961 0.945574 12.4545V12.4545L1.47189 13.3622C1.67749 13.7332 2.02245 14.007 2.43045 14.1229C2.83844 14.2389 3.27582 14.1875 3.6458 13.9801V13.9801C4.00952 13.7678 4.44294 13.7097 4.84973 13.8185C5.25653 13.9274 5.60298 14.1942 5.81209 14.5598C5.9492 14.7908 6.02288 15.0539 6.02567 15.3225V15.3225C6.02567 16.203 6.73941 16.9167 7.61987 16.9167H8.66487C9.54236 16.9168 10.2549 16.2076 10.2591 15.3302V15.3302C10.257 14.9067 10.4243 14.5001 10.7238 14.2006C11.0232 13.9012 11.4298 13.7339 11.8533 13.736C12.1213 13.7431 12.3833 13.8165 12.6161 13.9495V13.9495C13.3767 14.3878 14.3485 14.1287 14.79 13.3698V13.3698L15.3392 12.4545C15.5517 12.0896 15.6101 11.655 15.5013 11.247C15.3925 10.839 15.1255 10.4912 14.7595 10.2806V10.2806C14.3934 10.07 14.1264 9.72219 14.0176 9.31416C13.9088 8.90613 13.9672 8.47156 14.1797 8.10668C14.318 7.86532 14.5181 7.66521 14.7595 7.52697V7.52697C15.5137 7.08579 15.7723 6.11962 15.3392 5.36068V5.36068V5.35305Z" :stroke="settingsPath.includes(path) || tour.start && tour.stage === 5 ?  'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
            <ellipse cx="8.14602" cy="8.90773" rx="2.1968" ry="2.1968" :stroke="settingsPath.includes(path) || tour.start && tour.stage === 5 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [settingsPath.includes(path) || tour.start && tour.stage === 5 ? 'text-white' : 'text-drawer-text']]">Settings</p>
        </router-link>
      </div>
    </div>
    
    <tour
      v-if="tour.start"
      :outerClass="['xl:left-64', [showLink ? 'left-55' : 'left-24']]"
      :innerClass="[
        tour.stage === 1 ? [tour.stage === 1 ? 'top-64 xl:top-46' : ''] : '',
        tour.stage === 2 ? [tour.stage === 2 ? 'top-87.5 xl:top-70' : ''] : '',
        tour.stage === 3 ? [[tour.stage === 3 ? 'xl:bottom-0' : ''], [showLink ? 'bottom-0' : 'bottom-0']] : '',
      ]"
      :title="tour.title"
      :description="tour.description"
      @back="back(tour.stage)"
      @next="next(tour.stage)"
    />
    <!-- <tour
      v-if="tour.start"
      :outerClass="['xl:left-64', [showLink ? 'left-55' : 'left-24']]"
      :innerClass="[
        tour.stage === 1 ? [tour.stage === 1 ? 'top-64 xl:top-46' : ''] : '',
        tour.stage === 2 ? [tour.stage === 2 ? 'top-87.5 xl:top-70' : ''] : '',
        tour.stage === 3 ? [[tour.stage === 3 ? 'xl:top-85' : ''], [showLink ? 'top-105' : 'top-102']] : '',
        tour.stage === 4 ? [[tour.stage === 4 ? 'xl:top-100' : ''], [showLink ? 'top-119' : 'top-115']] : '',
        tour.stage === 5 ? [[tour.stage === 5 ? 'xl:bottom-0' : ''], [showLink ? 'bottom-0' : 'bottom-0']] : '',
      ]"
      :title="tour.title"
      :description="tour.description"
      @back="back(tour.stage)"
      @next="next(tour.stage)"
    /> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Tour from './Tour.vue'

export default {
  name: 'Drawer',
  components: {
    Tour
  },
  props: {
    showFullLink: Boolean
  },
  computed: {
    path() {
      return this.$route.path
    },
    ...mapState({
      tour: state => state.tour
    }),
    getUser() {
      return JSON.parse(localStorage.getItem('userDetails'))
    },
  },
  data() {
    return {
      profile: {},
      showLink: false,
      compliancePaths: ['/compliance/company', '/compliance/contact', '/compliance/documents', '/compliance/review'],
      originationPaths: ['/origination/pending', '/origination/approved', '/origination/rejected'],
      originationDetailsPaths: ['/origination/borrower/info', '/origination/borrower/loan', '/origination/borrower/documents'],
      settingsPath: ['/settings/profile', '/settings/team', '/settings/team/roles', '/settings/api', '/settings/products'],
      verificationPaths: ['/verification/request', '/verification/status'],
      tourGuide: [
        {
          title: 'Home',
          description: 'Get an overview of your loan origination process. View in-depth analysis of top perfoming loan products.'
        },
        {
          title: 'Origination',
          description: 'View leads, select the ones that fit your criteria and proceed to reviewing applications.'
        },
        // {
        //   title: 'Reports',
        //   description: 'View metrics and figures to see how your loan products are performing.'
        // },
        // {
        //   title: 'Billings',
        //   description: 'See how much you have left in your wallet, top up to continue receiving leads.'
        // },
        // {
        //   title: 'Activity log & Settings',
        //   description: 'Manage your account settings and view activity from here.'
        // },
        {
          title: 'Settings',
          description: 'Manage your account settings and activity from here.'
        },
      ]
    }
  },
  methods: {
    showFullDrawer() {
      this.$emit('showFullDrawer')
      this.showLink = !this.showLink
    },
    back(stage) { 
      if (this.tour.stage <= 5) {
        this.$store.commit('SET_TOUR', {
          ...this.tour,
          stage: stage === 1 ? stage : stage -= 1,
          title: this.tourGuide[stage - 1].title,
          description: this.tourGuide[stage - 1].description
        })
      }
    },
    next(stage) {
      if (this.tour.stage <= 3) {
        this.$store.commit('SET_TOUR', {
          start: stage === 3 ? false : true,
          done: stage === 3 ? true : false,
          stage: stage < 3 ? stage += 1 : stage,
          title: this.tourGuide[stage - 1].title,
          description: this.tourGuide[stage - 1].description
        })
      }
    },
  }
}
</script>

<style scoped>
.drawer-scroll::-webkit-scrollbar {
  display: none;
}
</style>