let id = 0

export const applicationData = [
  {
    id: id += 1,
    loanID: '#ID-123456',
    name: 'Amarachi Gloria Chinedu - Okoro',
    email: 'Amarachi2745@gmail.com',
    phone: '08099999999',
    amount: 5000000,
    loanType: 'Individual'
  },
  {
    id: id += 1,
    loanID: '#ID-123456',
    name: 'Amarachi Gloria Chinedu - Okoro',
    email: 'Amarachi2745@gmail.com',
    phone: '08099999999',
    amount: 5000000,
    loanType: 'Individual'
  },
  {
    id: id += 1,
    loanID: '#ID-123456',
    name: 'Amarachi Gloria Chinedu - Okoro',
    email: 'Amarachi2745@gmail.com',
    phone: '08099999999',
    amount: 5000000,
    loanType: 'Individual'
  },
  {
    id: id += 1,
    loanID: '#ID-123456',
    name: 'Amarachi Gloria Chinedu - Okoro',
    email: 'Amarachi2745@gmail.com',
    phone: '08099999999',
    amount: 5000000,
    loanType: 'Individual'
  },
  {
    id: id += 1,
    loanID: '#ID-123456',
    name: 'Amarachi Gloria Chinedu - Okoro',
    email: 'Amarachi2745@gmail.com',
    phone: '08099999999',
    amount: 5000000,
    loanType: 'Individual'
  },
  {
    id: id += 1,
    loanID: '#ID-123456',
    name: 'Amarachi Gloria Chinedu - Okoro',
    email: 'Amarachi2745@gmail.com',
    phone: '08099999999',
    amount: 5000000,
    loanType: 'Individual'
  },
  {
    id: id += 1,
    loanID: '#ID-123456',
    name: 'Amarachi Gloria Chinedu - Okoro',
    email: 'Amarachi2745@gmail.com',
    phone: '08099999999',
    amount: 5000000,
    loanType: 'Individual'
  },
  {
    id: id += 1,
    loanID: '#ID-123456',
    name: 'Amarachi Gloria Chinedu - Okoro',
    email: 'Amarachi2745@gmail.com',
    phone: '08099999999',
    amount: 5000000,
    loanType: 'Individual'
  },
  {
    id: id += 1,
    loanID: '#ID-123456',
    name: 'Amarachi Gloria Chinedu - Okoro',
    email: 'Amarachi2745@gmail.com',
    phone: '08099999999',
    amount: 5000000,
    loanType: 'Individual'
  },
  {
    id: id += 1,
    loanID: '#ID-123456',
    name: 'Amarachi Gloria Chinedu - Okoro',
    email: 'Amarachi2745@gmail.com',
    phone: '08099999999',
    amount: 5000000,
    loanType: 'Individual'
  },
]
