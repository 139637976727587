<template>
  <div @click.self="closeTour" :class="outerClass" class="fixed top-0 right-0 bottom-0 bg-tour-bg z-50">
    <div :class="innerClass" class="w-fit-content absolute">
      <div class="arrow"></div>
      <div class="bg-white p-5 rounded-lg w-60 h-55 flex flex-col justify-between ml-3">
        <div class="">
          <h6 class="text-title font-family-semibold">{{ title }}</h6>
          <p class="text-tour-text mt-1">{{ description }}</p>
        </div>
        <div class="ml-auto">
          <button @click.prevent="back" v-if="title !== 'Home'" class="w-20.5 py-3 bg-white border border-border rounded-lg focus:ring-4 focus:outline-none text-tour-text font-family-semibold">Back</button>
          <button @click.prevent="next" class="w-20.5 py-3 bg-primary focus:ring-4 focus:outline-none rounded-lg text-white font-family-semibold ml-2">
            {{ tour.stage === 3 ? 'End Tour' : 'Next' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Tour',
  props: {
    outerClass: Array,
    innerClass: Array,
    title: String,
    description: String
  },
  computed: {
    ...mapState({
      tour: state => state.tour
    })
  },
  methods: {
    back() {
      this.$emit('back')
    },
    next() {
      this.$emit('next')
    },
    closeTour() {
      this.$store.commit('SET_TOUR', {
        stage: 1,
        title: 'Home',
        description: 'View metrics and charts to know how your loan products are performing.',
        start: false,
        done: false
      })
    }
  }
}
</script>

<style scoped>
.arrow {
  width: 0;
  height: 0;
  border-right: 14px solid white;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-radius: 4px;
  position: absolute;
  top: 66px;
}
</style>