<template>
  <div :class="wrapperClass" class="fixed top-0 right-0 bg-white xl:left-64 px-8 py-4.5 flex items-center justify-between border-b border-border z-30">
    <h3 class="text-5 text-title font-family-semibold">{{ pageName }}</h3>
    <div class="flex items-center">
      <p :class="mode === 'Active' ? 'border-success text-title' : 'border-primary border-opacity-50 text-place-text'" class="px-4.5 py-2 border border-primary border-opacity-50 rounded-lg font-family-semibold text-xs">{{ mode }}</p>
      <div class="ml-6 cursor-pointer">
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.55339 10.3512V10.1764C1.57904 9.65923 1.74479 9.15814 2.03355 8.7248C2.5142 8.20424 2.84323 7.56633 2.98612 6.87799C2.98612 6.34599 2.98612 5.80639 3.03259 5.27439C3.27267 2.71319 5.80512 0.942383 8.30659 0.942383H8.36855C10.87 0.942383 13.4025 2.71319 13.6503 5.27439C13.6968 5.80639 13.6503 6.34599 13.689 6.87799C13.8338 7.56792 14.1625 8.20781 14.6416 8.7324C14.9325 9.16191 15.0985 9.66116 15.1217 10.1764V10.3436C15.139 11.0384 14.8997 11.7159 14.448 12.2512C13.851 12.8771 13.0409 13.2664 12.1711 13.3456C9.62042 13.6192 7.04697 13.6192 4.4963 13.3456C3.62744 13.263 2.81853 12.8742 2.21942 12.2512C1.77464 11.7155 1.53856 11.042 1.55339 10.3512Z" stroke="#757D8A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6.38574 15.9902C6.78428 16.4905 7.36953 16.8142 8.01197 16.8898C8.65441 16.9654 9.30106 16.7866 9.80881 16.393C9.96497 16.2766 10.1055 16.1413 10.227 15.9902" stroke="#757D8A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div v-if="getUser.user" class="flex items-center ml-6">
        <div @click="logout = !logout" class="flex items-center cursor-pointer">
          <img
            v-if="getUser.profileImage !== null"
            :src="getUser.profileImage"
            alt=""
            class="w-9 h-9 rounded-full object-cover"
          />
          <span
            v-else
            class="w-8 h-8 font-family-semibold rounded-full flex items-center justify-center text-white bg-primary"
          >
            {{ getUser.user.firstName[0] }}{{ getUser.user.lastName[0] }}
          </span>
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="ml-2 lg:inline hidden"
            :class="logout ? 'transform rotate-180' : ''"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.73353 0.600098L9.48778 1.35434L4.84399 5.99814L0.200195 1.35434L0.954443 0.600098L4.84399 4.48964L8.73353 0.600098Z"
              fill="#6D7D93"
            />
          </svg>
        </div>
        <div @click="logoutUser" v-if="logout" class="bg-white w-30 rounded-lg hidden lg:flex justify-center py-4 text-primary font-family-semibold absolute mt-32 border border-border lg:-ml-16 cursor-pointer">Logout</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Navbar',
  props: {
    wrapperClass: String
  },
  computed: {
    pageName() {
      return this.$route.name
    },
    getUser() {
      return {...JSON.parse(localStorage.getItem('userDetails')), profileImage: null}
    },
    ...mapState({
      user: state => state.user,
      mode: state => state.accountStatus
    }),
  },
  data() {
    return {
      logout: false,
      profile: {
        user: {
          firstName: 'Ikenna',
          lastName: 'Oyiih',
          profileImage: null
        }
      },
      logoutUser() {
        localStorage.clear();
        window.location.href = '/login'
      },
    }
  }
}
</script>

<style>

</style>